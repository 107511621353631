/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_eLBO6TdQb",
    "aws_user_pools_web_client_id": "1op29o883a6rrjfnotsgg4did6",
    "oauth": {
        
    },
    "API" : {
        "endpoints" : [
            {
                "name": "CarLocations",
                "endpoint": "https://dev.api.ab-carshare.glazleo.people.aws.dev/carLocation",            
            }
        ]
    },
    "aws_cloud_logic_custom": [
        {
            "name": "CarLocations",
            "endpoint": "https://dev.api.ab-carshare.glazleo.people.aws.dev/carLocation",
            "region": "ca-central-1"
        }
    ]
};


export default awsmobile;
