import React, { Component, Fragment  } from 'react';
import { API, Auth} from 'aws-amplify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

library.add(faEdit);

export default class Cars extends Component {

  state = {
    carLocations: [],
    carLocationsLoaded: false
  };
 
  setCarLocations = carLocations => {
    this.setState({ isAuthenticated: carLocations });
  }
  
  setCarLocationsLoaded = loaded => {
    this.setState({ carLocationsLoaded: loaded });
  }

  
  getAvailableCars = async() => {
    try {
        const user = await Auth.currentAuthenticatedUser()
        console.log("User:" + user)
        const token = user.signInUserSession.idToken.jwtToken
        console.log("Token: " +token)
        const requestInfo = {
          headers: {
            Authorization:  token
          }
        }
        const response = await API.get('CarLocations','/?latitude=51.04576150325974&longitude=-114.05622939693309&radius=25000', requestInfo)
        console.log({response})
        this.setState({carLocations : response});
        this.state.carLocations.map(location =>(console.log("CarId: " +location.carId)))


    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }
  

  componentDidMount = async () => {
      await this.getAvailableCars();
      console.log("pageLoaded")
  }

  render() {
    
    return (
      <Fragment>
        <section className="section">
          <div className="container">
            <h1>Available Cars (within 25 km)</h1>
            
            <div className="tile is-4 is-parent  is-vertical">
            
        
                <table striped borderd hover  width="75%">
                    <thead>
                        <tr>
                            <th nowrap>CarId</th>
                            <th nowrap>Location</th>
                            <th nowrap>Latitude</th>
                            <th nowrap>Longitude</th>
                            <th nowrap>Car Status</th>
                        </tr>
                    </thead>
                    <tbody>
            
                {this.state.carLocations.map(location => {
                 return (
                    <tr>
                    <td >{location.carId}</td>
                    <td >{location.description}</td>
                    <td >{location.latitude}</td>
                    <td >{location.longitude}</td>
                    <td >{location.carStatus}</td>
                    </tr> 
                    )
                 })}
                        
                         
                         
                 </tbody>
            </table>
           
           </div>
          </div>
        </section>
      </Fragment>
    )
  }
}