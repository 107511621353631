import React, { Fragment } from 'react';

export default function Home() {
  return (
    <Fragment>
      <div className="box cta">
        <p className="has-text-centered">
         Welcome to the Springfield Car Share Service! 
        </p>
      </div>
    </Fragment>
  )
}